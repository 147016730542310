import React from "react";
import LetsTalkArea from "../../common/LetsTalkArea";
import Append from "../../common/Append";

const Web = () => {
  return (
    <>
     
      <section className="service-details sec-mar">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-12 col-xl-12 or2">
              

<div class="container mt-2 m-auto mb-3 rounded-3 containerd">
  <h1>Take a step towards<br/>becoming <span>a brand</span>.</h1>
  <p className="text-white">Unlock business opportunities with Novarsistech's unmatched web development services,<br/>each one bringing us closer to the future and farther from the past</p>
  <div class="bg"></div>
</div>
              <div className="signle-service-details">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/icons/service-details-icon.svg"
                  }
                  alt="images"
                />
                <h3>We Analyse and Educate</h3>
                <p>
                  In euismod lacinia rhoncus. Morbi ornare, lectus quis mattis
                  finibus, metus sapien venenatis orci,in eget lacinia magna
                  justo vehicula metus. Morbi sit amet erat faucibus, sagittis
                  libero sed, thatenigr condimentum tortor. Aenean ac nunc
                  dolor. Quisque vestibulum mollis nisi, vel dictum nisi. nangol
                  Vestibulum tempor tristique neque non pretium. Etiam leo
                  risus, consectetur sagittis ullamcorper scelerisque, blandit
                  vitae sem. Etiam semper enim sapien, nec consequat lectus
                  pellentesque sit amet. Curabitur viverra ac tortor a
                  porttitor. Donec tristique, dui at molestie facilisis, justo
                  dolor on dapibus urna, sit amet viverra elit neque a
                  lectus.Etiam semper enim sapien, nec consequat lectus
                  pellentesque sit amet.Fusce mollis faucibus malesuada.
                  Curabitur et enim auctor, rhoncus elit then fermentum, luctus
                  tortor. Curabitur quam nisi, ornare elementum tellus eget,
                  nangol Vestibulum tempor tristique neque non pretium. Etiam
                  leo risus, consectetur sagittis ullamcorper scelerisque,
                  blandit vitae sem. Etiam semper enim sapien, nec consequat
                  lectus pellentesque sit amet. Curabitur viverra ac tortor a
                  porttitor. Donec tristique, dui at molestie facilisis, justo
                  dolor on dapibus urna, sit amet viverra elit neque a lectus.
                  luctus maximus fore lectus. Sed egestas facilisis convallis.
                  Proin dapibus magna vitae dolor accumsan, a egestas velitEtiam
                  semper enim sapien.
                </p>
              </div>
            </div>
            </div>
          <div className="single-service-work-process">
            <h3>How We Work in our services</h3>
            <div className="single-step">
              <div className="row">
                <div className="col-md-8 col-xl-8 or2">
                  <div className="step">
                    <div className="step-count">
                     
                    </div>
                    <h4>Wireframe &amp; Design</h4>
                    <p>
                    A bunch discourse is one of the leading ways to form a sense of community and collaboration. Once You're in a gather talking about the subject of your choice. You've got the floor to share your conclusion and inquire any questions that will emerge in your intellect.
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-xl-4 or1">
                  <div className="step-img">
                  <img className='border rounded-3 ' 
                      src={process.env.PUBLIC_URL + "/images/ip.jpg"}
                      alt="images"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="single-step">
              <div className="row">
                <div className="col-md-4 col-xl-4">
                  <div className="step-img">
                  <img
                      src={process.env.PUBLIC_URL + "/images/native.jpg"}
                      alt="images"
                    />
                  </div>
                </div>
                <div className="col-md-8 col-xl-8">
                  <div className="step">
                    <div className="step-count">
                     
                    </div>
                    <h4>Developing</h4>
                    <p>
                    the method of making program frameworks which are dependable, viable, secure and extendable. The objective of computer program building is to deliver a item that can be conveyed inside time, taken a toll, and quality; assembly all necessities; assembly desires; minimizing dangers; maximizing benefits; giving a competitive advantage over competitors.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="single-step">
              <div className="row">
                <div className="col-md-8 col-xl-8 or2">
                  <div className="step">
                    <div className="step-count">
                     
                    </div>
                    <h4>Checkup &amp; Launch</h4>
                    <p>
                    We value people who are starting to take responsibility for their work. <br/>Novarsis likes to work with people who are willing to collaborate with friendship, share ideas and take responsibility for results.
             
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-xl-4 or1">
                  <div className="step-img">
                  <img
                      src={process.env.PUBLIC_URL + "/images/nvr/lv2.png"}
                      alt="images"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
        
      </section>
    <Append/>
      <LetsTalkArea/>
    </>
  );
};

export default Web;
