import React from "react";

function ProjectProcess() {
  return (
    <>
      <div className="project-process">
       
        <div className="project-overview">
          <h3>OVERVIEW</h3>
          <div className="row">
            <div className="col-md-5 col-lg-5 col-xl-5">
              <div className="overview-img">
                <img
                  src={process.env.PUBLIC_URL + "/images/overview-1.jpg"}
                  alt="images"
                />
              </div>
            </div>
            <div className="col-md-5 col-lg-7 col-xl-7 or2">
              <div className="overview-content">
                <h3>Our Project Scenario Brief</h3>
                <p className="text-justify">
                Customized solutions allow customers to meet their business needs. 
An efficient team of designers, manufacturers and marketers provides custom development on request. 
Vertical integration and diversification across platforms, including business, retail, real estate, automotive, hospitality, travel, and other B2B and B2C activities. The
Web Solution has a powerful interactive user interface that reduces operating costs and time. 
Full-featured web and mobile application development for maximum conversion. 
Secure API payment gateway integration. 

         </p>
         <br/>
         <p className="text-justify">
We get it that each trade needs diverse IT arrangements and concepts so we work difficult to supply them with the correct arrangement for your trade needs.



We are a group of IT experts with encounter in conveying trade arrangements for little to medium businesses. And we specialize in giving quality services at reasonable prices.



Our arrangements are based on our understanding of the wants of businesses and their customers. Our objective is to assist our clients develop their income by giving them modern and inventive arrangements to assist you spare time, cash and exertion when it comes to your commerce.
</p>
              </div>
            </div>
          </div>
        </div>
        <div className="project-overview right">
          <h3>PROJECT PROCESS</h3>
          <div className="row">
         
           
           
            <div className="col-md-6 col-lg-3 col-xl-3 or1">
              <div className="project-single-step">
                <h4>01. Brainstorming</h4>
                <div className="project-step-img">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/nvr/br1.png"
                    }
                    alt="images"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 col-xl-3 or2">
              <div className="project-single-step">
                <h4>02. Wireframe</h4>
                <div className="project-step-img">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/nvr/br2.png"
                    }
                    alt="images"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 col-xl-3 or3">
              <div className="project-single-step">
                <h4>03. UI Design</h4>
                <div className="project-step-img">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/nvr/br3.png"
                    }
                    alt="images"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 col-xl-3 or4">
              <div className="project-single-step">
                <h4>04. Developing</h4>
                <div className="project-step-img">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/nvr/develop.png"
                    }
                    alt="images"
                  />
                </div>
              </div>
            </div>
          
          </div>

        </div>
        <div className=" project-overview">
         
          <div className="row">
          
            <div className="mx-lg-4 col-md-5 col-lg-5 col-xl-5 or1">
            <h3 className=" text-secondary">Check &amp; launch</h3>
              <div className="overview-content">
                <p>
                We value people who are starting to take responsibility for their work. <br/>Novarsis likes to work with people who are willing to collaborate with friendship, share ideas and take responsibility for results.
                </p>
              </div>
            </div>
            <div className=" mx-lg-4 col-md-5 col-lg-5 col-xl-5 or1">
            <h3 className=" text-secondary">Trust with work</h3>
            <div className="overview-content">
                <p>
                We believe honesty and transparency are the keys to effective communication and building trust. </p>
              </div>
            </div>
          </div>
          <div className="row mt-lg-4">
          
          <div className="mx-lg-4 col-md-5 col-lg-5 col-xl-5 or1">
          <h3 className=" text-secondary">believing on Teamwork</h3>
            <div className="overview-content">
              <p>
              We think that making a difference each other is an critical portion of day-to-day work. Whether a individual is working alone or with a group. </p>
            </div>
          </div>
          <div className=" mx-lg-4 col-md-5 col-lg-5 col-xl-5 or1">
          <h3 className=" text-secondary">Successful Deploy</h3>
          <div className="overview-content">
              <p>
              The ultimate organize of the site’s creation is Establishment. Once it is confirmed that the internet location capacities in craved shape, it is distributed on your open Web server for common individuals to capture a see of it. After affirmation location is submitted to look motors and catalogs.  </p>
            </div>
          </div>
        </div>
        </div>
      </div>
    </>
  );
}

export default ProjectProcess;
