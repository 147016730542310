import React from "react";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";

SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);
function TestimonialArea() {
  const testimonialSlider = {
    slidesPerView: 1,
    loop: true,
    speed: 2000,
    autoplay: {
      delay: 5000,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  };
  return (
    <>
      <section className="testimonial-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="swiper testimonial-slider">
                <Swiper
                  pagination={{

                  }}
                  {...testimonialSlider}
                  className="swiper-wrapper"
                >
                  <SwiperSlide className="swiper-slide">
                    <div className="testimonial-content">
                      <div className="quote">
                        <i className="fas fa-quote-left" />
                      </div>
                      <div className="client-info">
                        {/* <div className="client-pic">
                          <img
                            src={process.env.PUBLIC_URL + "/images/client.jpg"}
                            alt="images"
                          />
                        </div> */}
                        <div className="client-details">
                          <h4>Pradeep Mahateo</h4>
                          {/* <span>Executive CEO</span> */}
                        </div>
                      </div>
                      <p>
                        <i className="fas fa-quote-left" /> I Believe NovarsisTech is the best for a newcomer, will get to learn more about the product and language, good environment to work in, and all the TL and HR are helpful to us.
                        <i className="fas fa-quote-right" />
                      </p>
                      <div className="rating">
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="testimonial-content">
                      <div className="quote">
                        <i className="fas fa-quote-left" />
                      </div>
                      <div className="client-info">
                        {/* <div className="client-pic">
                          <img
                            src={
                              process.env.PUBLIC_URL + "/images/client-2.jpg"
                            }
                            alt="images"
                          />
                        </div> */}
                        <div className="client-details">
                          <h4>Akshay Sharma</h4>
                          {/* <span>CTO Founder</span> */}
                        </div>
                      </div>
                      <p>
                        <i className="fas fa-quote-left" /> Good management, good work atmosphere, and attractive monthly incentives as per your performance. NovarsisTech knows its employees and their needs and how to keep them enthusiastic at work.
                        <i className="fas fa-quote-right" />
                      </p>
                      <div className="rating">
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="testimonial-content">
                      <div className="quote">
                        <i className="fas fa-quote-left" />
                      </div>
                      <div className="client-info">
                        {/* <div className="client-pic">
                          <img
                            src={
                              process.env.PUBLIC_URL + "/images/client-3.jpg"
                            }
                            alt="images"
                          />
                        </div> */}
                        <div className="client-details">
                          <h4>Gautam Yadav</h4>
                          {/* <span>Designer Head</span> */}
                        </div>
                      </div>
                      <p>
                        <i className="fas fa-quote-left" /> Experience completely depends on the team you are working with. Good projects with a lot to learn, Multiple free training and certification are available for employees, with good benefits.
                        <i className="fas fa-quote-right" />
                      </p>
                      <div className="rating">
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
                <div className="swiper-button-next" />
                <div className="swiper-button-prev" />
                <div className="swiper-pagination" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TestimonialArea;
