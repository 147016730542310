import React from "react";
import { SRLWrapper } from "simple-react-lightbox";
// import CartFilter from "../../common/CartFilter";
import ProjectProcess from "../projectDetails/ProjectProcess";
import LetsTalkArea from "../../common/LetsTalkArea";

function ProjectWrapper() {
  return (
    <>
      <section className="project-area sec-mar">
        <div className="container">
          <SRLWrapper>
           
            <ProjectProcess/>
            <LetsTalkArea/>
            
            {/* <CartFilter /> */}
          </SRLWrapper>
        </div>
      </section>
    </>
  );
}

export default ProjectWrapper;
