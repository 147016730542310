import React from "react";
import TestimonialArea from "../../common/TestimonialArea";
import AboutArea from "./AboutArea";
import HeroArea from "./HeroArea";
import LetsTalkArea from "../../common/LetsTalkArea";

import ServiceArea from "./ServiceArea";
import WhyChooseUsArea from "../../common/WhyChooseUsArea";

function HomePage() {
  return (
    <>
      <HeroArea />
      <ServiceArea />
      <AboutArea black="black" light="dark" />
     
     
      <WhyChooseUsArea black="black" lable="" />
      <TestimonialArea />
    
      <LetsTalkArea />
    </>
  );
}

export default HomePage;
