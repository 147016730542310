import React from 'react'

import Card from 'react-bootstrap/Card';
const Append = () => {
  return (
   <>
   <Card className="text-center">
     
     <Card.Body>
       <Card.Title className="display-1">  Technologies we work on</Card.Title>
       <Card.Text>
       Our team of expert developers works on the latest technologies to keep up you update and upgrade. <br/>
       <p className='text-decoration-underline'> we are open for working any Technology that our clients want</p>
       </Card.Text>
      
     </Card.Body>
    
   </Card>
   </>
  )
}

export default Append