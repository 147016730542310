import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import SimpleReactLightbox from "simple-react-lightbox";
import Layout from "./components/App";
import MainLayout from "./components/layout/MainLayout";
import AboutPage from "./components/pages/aboutUs/AboutPage";
import CommingSoonPage from "./components/pages/commingSoon/CommingSoonPage";
import ContactPage from "./components/pages/contact/ContactPage";
import ErrorPage from "./components/pages/Error/ErrorPage";
import ProjectsPage from "./components/pages/projects/ProjectsPage";
import ServicesPage from "./components/pages/service/Serevices";
import Privacy from "./components/common/Privacy";
import ServiceDetails from "./components/pages/ServiceDetails/ServiceDetails";

// all css import
import "./index.css";
import Web from "./components/pages/service/Web";
import App from "./components/pages/service/AppDevelopment";
import Ecommerce from "./components/pages/service/Ecom";
import Cms from "./components/pages/service/Cms";
import strgt from "./components/pages/service/strgt";
import Media from "./components/pages/service/Media"

//Default Warniing Error Hide
console.log = console.warn = console.error = () => {};

/*
=>version : 0.1
=>Event : Rendering al content to web
=>Action: define all routes and page
@return HTML
*/

function Root() {
  return (
    <>
      <BrowserRouter basename="/">
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/commingsoon`}
            component={CommingSoonPage}
          />
          <Route exact path="/" component={MainLayout} />
           <Layout>
           
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/about`}
              component={AboutPage}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/service`}
              component={ServicesPage}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/service-details`}
              component={ServiceDetails}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/project`}
              component={ProjectsPage}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/privacy-policy`}
              component={Privacy}
            />
           
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/error`}
              component={ErrorPage}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/contact`}
              component={ContactPage}
            />
             <Route
              exact
              path={`${process.env.PUBLIC_URL}/webdevelopment`}
              component={Web}
            />
              <Route
              exact
              path={`${process.env.PUBLIC_URL}/Appdevelopment`}
              component={App}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/DigitalMediaMarketing`}
              component={Media}
            />
              <Route
              exact
              path={`${process.env.PUBLIC_URL}/e-commerce`}
              component={Ecommerce}
            />

<Route
              exact
              path={`${process.env.PUBLIC_URL}/Hirededicated`}
              component={strgt}
            />
<Route
              exact
              path={`${process.env.PUBLIC_URL}/CMSDevelopment`}
              component={Cms}
            />
          </Layout>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default Root;

ReactDOM.render(
  <React.StrictMode>
    <SimpleReactLightbox>
      <Root />
    </SimpleReactLightbox>
  </React.StrictMode>,
  document.getElementById("root")
);
