/* eslint-disable jsx-a11y/iframe-has-title */
import React,{useRef,useState} from "react";

function ContactForm() {
  const formRef = useRef(null)
  const scriptUrl = "https://script.google.com/macros/s/AKfycbxSD8vjwrM6K_1-p8cpAwQIFyH-m65JZVMb6WusMTL1pEUfe-iUEde7VKVs01uPqsrJLw/exec"
  const [loading, setLoading] = useState(false)
  
  const handleSubmit = (e) =>{
    e.preventDefault()
    setLoading(true)
  
    fetch(scriptUrl, {
      mode: 'no-cors',
      method: 'POST',
      body : new FormData(formRef.current),
    }).then (res => {
      console.log(res);
      console.log("Successful");
      setLoading(false)
    })
    .catch(err => console.log(err))
  }
  return (
    <>
      <div className="contact-information">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-xl-6">
              <div className="contact-form">
                <h3>Have Any Questions</h3>
                <form
                ref={formRef} name="Sheet1" onSubmit={handleSubmit}
                
                >
                  <div className="row">
                    <div className="col-xl-6">
                      <input
                        type="text"
                        name="name"
                        placeholder="Enter your name"
                      />
                    </div>
                    <div className="col-xl-6">
                      <input
                        type="text"
                        name="Mobile"
                        placeholder="Enter your Contact Number"
                      />
                    </div>
                    <div className="col-xl-6">
                      <input
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                      />
                    </div>
                    <div className="col-xl-6">
                      <input type="text" name="subject" placeholder="Subject" />
                    </div>
                    <div className="col-12">
                      <textarea
                        name="message"
                        cols={30}
                        rows={10}
                        placeholder="Your message"
                      
                      />
                    </div>
                    <div className="col-12">
                      <input type="submit"  value={loading ? "loading...": "send message"} />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6 col-xl-6">
              <div className="google-map">
                <iframe
                  src="https://www.google.com/maps/d/embed?mid=1YQLO70Ay9Fhkttgn-AXCp_IFZyo&hl=en_US&ehbc=2E312F" width="640" height="480"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactForm;


