import React from 'react'
import LetsTalkArea from '../../common/LetsTalkArea'
import { Link } from 'react-router-dom'
const Cms = () => {
  return (
    <>
      <div class="herocmd">
 
 <div class="content">
   <small>Unlock your golden Business Opportunities With NovarsisTech</small>
   <h2 className='dispaly-1'>Novarsistech's Top-Rated Enterprise Company<br/> <br/>That Improve business access with CMS Service Solutions.</h2>
   <Link to="/contact">  <button class="buttond" type="button">Contact Us</button></Link>
 </div>
 
 
 <div class="bubbles">

 </div>


</div>
<section>
  <div id='budget' class="container-fluid mt-5  m-auto">
    <div class="container">
    <h2 className=' d-flex display-1 mt-3 justify-content-center'>Advantage of CMS Development</h2>
      <div class="row">
       
        <div class="col-sm-4 mx-auto">
          <div class="card card_violet text-center">
            <div class="title">
              <i class="fa fa-check" aria-hidden="true"></i>
              <h2>
SEO-Friendly</h2>
            </div>
          
          
          </div>
        </div>
        <div class="col-sm-4 mx-auto">
          <div class="card  card_three text-center">
            <div class="title">
              <i class="fa fa-check text-black-50" aria-hidden="true"> 
              </i>
              <h2>Easy Available Theme</h2>
            </div>
           
          
          </div>
        </div>
        <div class="col-sm-4 mx-auto">
          <div class="card card_red mb-10 text-center">
            <div class="title">
              <i class="fa  fa-check" aria-hidden="true"></i>
              <h2>Easy Maintenance</h2>
            </div>
          
          
          </div>
        </div>
      </div>

      <div class="row mt-lg-3  ">
        <div class="col-sm-4 mx-auto">
          <div class="card card_red mb-10 text-center">
            <div class="title">
              <i class="fa fa-check text-black-50" aria-hidden="true"></i>
              <h2>Reduce Cost</h2>
            </div>
           
          
          </div>
        </div>
        <div class="col-sm-4 mx-auto">
          <div class="card  card_three text-center">
            <div class="title">
              <i class="fa fa-check text-white" aria-hidden="true"> 
              </i>
              <h2>Responsive Design</h2>
            </div>
          
          
          </div>
        </div>
        <div class="col-sm-4 mx-auto">
          <div class="card card_violet text-center">
            <div class="title">
              <i class="fa fa-check text-black-50" aria-hidden="true"></i>
              <h2>High-Level Customization</h2>
            </div>
           
          
          </div>
        </div>
       
      </div>
    </div>
  </div>
</section>
    <section className="service-details sec-mar">
       
        <div className="container">
        <div className='  flex-column   col-12'>
        {/* <h1 className=' d-flex justify-content-center text-uppercase text-secondary mb-lg-12 '> Content Management Serevices</h1> */}
        </div>
          <div className="row">
            <div className="col-md-6 col-lg-12 col-xl-12 or2">
              <div className="signle-service-details">
            
               
               <p>
               Instead of designing and building systems that help create web pages or store images, you or your web designer can focus on your CMS website visitors, while CMS can be used to manage any real estate task.

Content Management System or CMS is software for creating, managing and editing websites even if you have no special skills or knowledge. With

CMS, you can design and build websites without writing code. Simply put, a CMS website is a website built using a content management system.

A CMS not only helps manage the text or images displayed on the website, but also helps to track users' time, conduct research questions, collect comments and reviews, host forums on the website, and more.    </p>
              </div>
            </div>
         
          </div>
          <div className="single-service-work-process">
            <h3>Majority of Content Management Services</h3>
            <div className="single-step">
              <div className="row">
                <div className="col-md-8 col-xl-8 or2">
                  <div className="step">
                    <div className="step-count">
                    
                    </div>
                    <h4>Open Source</h4>
                    <p>
                     <ul>
                        <li> Open source software is code created for general use - anyone can view, modify, and distribute the code as they see fit.
</li>
                        <li>  it is often less expensive, more flexible, and longer lasting than proprietary software.</li>
                        <ul> Some Open Source Tools are: <br/>
                        <li className='text-bold'>	✔ Joomla</li>
                        <li>	✔ Wordpress</li>
                        <li>	✔ Magento</li>
                        <li>	✔ Preshtshop</li>
                        
                        </ul>
                     </ul>
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-xl-4 or1">
                  <div className="step-img">
                    <img
                      src={process.env.PUBLIC_URL + "/images/nvr/zoomlla.png"}
                      alt="images"
                    />
                  </div>
                </div>
              </div>
            </div>
         
            <div className="row">
            <div className="col-xl-6">
              <div className="blog-banner-img ">
                <img  className='rounded-pill'
                  src={process.env.PUBLIC_URL + "/images/nvr/lv.png"}
                  alt="blog images"
                />
              </div>
            </div>
            <div className="col-xl-6 mt-3">
              <div className="blog-banner-content">
                <p>
                CMS development refers to the process of creating a content management system, which is a software application that allows users to manage and publish digital content on the web. CMS development involves designing and coding the system's backend architecture, user interface, and content creation and management tools. A CMS typically includes features such as user authentication, content creation and editing, search functionality
                </p>
              </div>
            </div>
          </div>
          </div>
        </div>
      </section>
      <LetsTalkArea/>
    </>
  )
}

export default Cms