import React from 'react'

import Card from 'react-bootstrap/Card';
import LetsTalkArea from "../../common/LetsTalkArea";
// import Append from '../../common/Append';
import { Link } from 'react-router-dom';
const AppDevelopment = () => {


  
  return (
    
	



   <>
     <div class="herodm">
 
 <div class="content">
   <small>Digital Marketing Agency in your Fingers</small>
   <h2 className='dispaly-1'>Novarsistech's Top-Rated Digital Marketing Agency <br/> <br/>That we create digital experiences which Clients love, and fulfill business have need!</h2>
   <Link to="/contact"> <button class="buttond" type="button">Contact Us</button></Link>
 </div>
 
 
 <div class="bubbles">

 </div>
</div>
    
    <section className="service-details sec-mar">
      
        <div className="container">
          
          <div className="row">
            <div className="col-md-6 col-lg-12 col-xl-12 or2">
              

         
              <div className="signle-service-details">
               
                <h3>Digital Media Marketing Services</h3>
                <p>
                Novarsistech is a reputed Agency with expertise in designing the most ,
We are a group of 50+ advanced specialists, storytellers, technologists, makers, and inquisitive minds, giving administrations within the regions of computerized communication, promoting, and innovation. We have a specialized Healthcare vertical catering to the pharma and wellness industry. We moreover have mastery in showcasing computerization, and e-commerce, with clients over India and around the globe.


 <br/>

We move forward with advanced technologies and equipment to meet your needs and exceed expectations. Thus, we create powerful and scalable apps for any mobile devices. We are tech-obsessed and have the perfect combination of skills, creativity, initiative and expertise. That's why we create truly reliable, fully functional and customized best apps to match the requirements of your business, brand and your target audience.
<br/>
 

We build mobile applications on various platforms using trending technologies. 
                </p>
              </div>
            </div>
          
          </div>
          <div className="single-service-work-process">
            <h3>
Our main digital product
</h3>
         <p>
Our digital marketing agency specializes in understanding the latest marketing trends and using different strategies, techniques and online tools to help your business achieve its target marketing and sales goals. No matter what products or services your business offers, Novarsis digital marketing solutions can make a difference anywhere. We help you target audiences with a global reach and target your audience to smaller audiences.
</p>
          </div>
         
        </div>
        
      </section>
      <Card className="text-center">
     
     <Card.Body>
       <Card.Title className="display-1">  Our Digital Marketing Services</Card.Title>
       <Card.Text>
       Our team of expert developers works on the latest technologies to keep up you update and upgrade. <br/>
       <p className='text-decoration-underline'> we are open for working any Technology that our clients want</p>
       </Card.Text>
      
     </Card.Body>
    
   </Card>
      {/* <Append/> */}
      
      <LetsTalkArea/>
  
   <section>
 
    
   </section>
   </>
  )
}

export default AppDevelopment