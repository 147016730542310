import React from 'react'

import LetsTalkArea from "../../common/LetsTalkArea";
import Append from '../../common/Append';
import { Link } from 'react-router-dom';
const AppDevelopment = () => {


  
  return (
    
	



   <>
     <div class="herod">
 
 <div class="content">
   <small>Mobile App Development Services</small>
   <h2 className='dispaly-1'>Novarsistech's Top-Rated Enterprise App Development Company<br/> <br/>That Transforms Your Dreams Into Visual Reality.</h2>
   <Link to="/contact"> <button class="buttond" type="button">Contact Us</button></Link>
 </div>
 
 
 <div class="bubbles">

 </div>
</div>
    
    <section className="service-details sec-mar">
      
        <div className="container">
          
          <div className="row">
            <div className="col-md-6 col-lg-12 col-xl-12 or2">
              

         
              <div className="signle-service-details">
               
                <h3>Mobile App Development Services</h3>
                <p>
                Novarsistech is a reputed mobile app development company with expertise in designing the most outstanding and cross-platform mobile apps. We offer a complete range of mobile apps development services.

 <br/>

We move forward with advanced technologies and equipment to meet your needs and exceed expectations. Thus, we create powerful and scalable apps for any mobile devices. We are tech-obsessed and have the perfect combination of skills, creativity, initiative and expertise. That's why we create truly reliable, fully functional and customized best apps to match the requirements of your business, brand and your target audience.
<br/>
 

We build mobile applications on various platforms using trending technologies. 
                </p>
              </div>
            </div>
          
          </div>
          <div className="single-service-work-process">
            <h3>App Development that we Covered</h3>
            <div className="single-step">
              <div className="row">
                <div className="col-md-8 col-xl-8 or2">
                  <div className="step">
                    <div className="step-count">
                      {/* <span>01</span> */}
                    </div>
                    <h5>Novarsistech offers advanced application development services</h5>
                    <p>
                    
                    Hire an Android developer from NovarsisTech to build scalable Android apps. Novarsistech is recognized as a leading Android app development company by well-established companies that ensures reliability.
<br/>
Android app development solutions from Novarsis help all business enterprises and organizations to make their presence felt in their relevant industries.

                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-xl-4 or1">
                  <div className="step-img m-auto  w-50">
                    <img className='border rounded-3'
                      src={process.env.PUBLIC_URL + "/images/app.png"}
                      alt="images"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="single-step">
              <div className="row">
                <div className="col-md-4 col-xl-4">
                  <div className="step-img m-auto">
                    <img className='border rounded-3 ' 
                      src={process.env.PUBLIC_URL + "/images/ip.jpg"}
                      alt="images"
                    />
                  </div>
                </div>
                <div className="col-md-8 col-xl-8">
                  <div className="step">
                    <div className="step-count">
                     
                    </div>
                    <h4>
iOS App Development Services</h4>
                    <p>
                    Hire an iPhone app developer to experience the luxury.<br/> Being the top iPhone app development company worldwide, we provide the best solutions at budget-friendly rates.
<br/>
The iPhone revolutionized mobile computing and Internet connectivity. Today, it has become an excellent tool for high profile people as well as businesses to connect with the intended audience and complete a number of activities.
                    </p>
                    <ul>
                      <li className='fa mt-3 fa-dailymotion'>Relationship And Loyalty Building</li> <br/>

                      <li className='fa mt-3 fa-dailymotion'>Increase Visibility And Accessibility</li><br/>
                      <li className='fa mt-3  fa-dailymotion'>Increase Extra Exposure Across Mobile Devices</li><br/>
                      <li className='fa mt-3 fa-dailymotion'>Connect With On-The-Go Consumers</li>
                      <li className='fa mt-3 fa-dailymotion'>Enhance New Marketing And Relationship Strategies</li>
                      <li className='fa mt-3 fa-dailymotion'>Reinforce Brand To Generate And Repeat Business</li>
                    </ul> 
                  </div>
                </div>
              </div>
            </div>
            <div className="single-step">
              <div className="row">
                <div className="col-md-8 col-xl-8 or2">
                  <div className="step">
                    <div className="step-count">
                     
                    </div>
                    <h4>Native App Development Services</h4>
                    <p>
                   <strong> React Native</strong> is a  JavaScript framework and code library developed by Facebook and Instagram. <br/> It allows you to build native applications in JavaScript while still providing a true native user interface that enables code reuse across web and mobile.

                   <br/>

                   <p> <strong> Flutter</strong> is an open-source UI software development kit created by Google. It is used to develop cross-platform applications for Android, iOS, Linux, macOS, Windows, Google Fuchsia, and the web from a single codebase.
                   <br/>
                 <strong>  You can hire a flexible mobile app team depending on the stage of your product and production plans.</strong>
                   </p>
              
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-xl-4 or1">
                  <div className="step-img">
                    <img
                      src={process.env.PUBLIC_URL + "/images/native.jpg"}
                      alt="images"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
         
        </div>
        
      </section>
     
      <Append/>
      <LetsTalkArea/>
   <section>
 
    
   </section>
   </>
  )
}

export default AppDevelopment