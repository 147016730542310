import React from "react";
function ServiceDetailsWrapper() {
 
  return (
    <>
      <section className="service-details sec-mar">
        <div className="container">
        <div className=" project-overview">
         
         <div className="row">
         
           <div className="mx-lg-4 col-md-5 col-lg-5 col-xl-5 or1">
           <h3 className=" text-secondary">Check &amp; launch</h3>
             <div className="overview-content">
               <p>
               We value people who are starting to take responsibility for their work. <br/>Novarsis likes to work with people who are willing to collaborate with friendship, share ideas and take responsibility for results.
               </p>
             </div>
           </div>
           <div className=" mx-lg-4 col-md-5 col-lg-5 col-xl-5 or1">
           <h3 className=" text-secondary">Trust with work</h3>
           <div className="overview-content">
               <p>
               We believe honesty and transparency are the keys to effective communication and building trust. </p>
             </div>
           </div>
         </div>
         <div className="row mt-lg-4">
         
         <div className="mx-lg-4 col-md-5 col-lg-5 col-xl-5 or1">
         <h3 className=" text-secondary">believing on Teamwork</h3>
           <div className="overview-content">
             <p>
             We think that making a difference each other is an critical portion of day-to-day work. Whether a individual is working alone or with a group. </p>
           </div>
         </div>
         <div className=" mx-lg-4 col-md-5 col-lg-5 col-xl-5 or1">
         <h3 className=" text-secondary">Successful Deploy</h3>
         <div className="overview-content">
             <p>
             The ultimate organize of the site’s creation is Establishment. Once it is confirmed that the internet location capacities in craved shape, it is distributed on your open Web server for common individuals to capture a see of it. After affirmation location is submitted to look motors and catalogs.  </p>
           </div>
         </div>
       </div>
       </div>
          <div className="row">
            <div className="col-md-6 col-lg-12 col-xl-12 or2">
              <div className="signle-service-details">
              {/* <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/icons/service-details-icon.svg"
                    }
                    alt="images"
                  /> */}
                <h3>
                  
                   We Analyse and Educate
                </h3>
                <p>
                  {/* In euismod lacinia rhoncus. Morbi ornare, lectus quis mattis
                  finibus, metus sapien venenatis orci,in eget lacinia magna
                  justo vehicula metus. Morbi sit amet erat faucibus, sagittis
                  libero sed, thatenigr condimentum tortor. Aenean ac nunc
                  dolor. Quisque vestibulum mollis nisi, vel dictum nisi. nangol
                  Vestibulum tempor tristique neque non pretium. Etiam leo
                  risus, consectetur sagittis ullamcorper scelerisque, blandit
                  vitae sem. Etiam semper enim sapien, nec consequat lectus
                  pellentesque sit amet. Curabitur viverra ac tortor a
                  porttitor. Donec tristique, dui at molestie facilisis, justo
                  dolor on dapibus urna, sit amet viverra elit neque a
                  lectus.Etiam semper enim sapien, nec consequat lectus
                  pellentesque sit amet.Fusce mollis faucibus malesuada.
                  Curabitur et enim auctor, rhoncus elit then fermentum, luctus
                  tortor. Curabitur quam nisi, ornare elementum tellus eget,
                  nangol Vestibulum tempor tristique neque non pretium. Etiam
                  leo risus, consectetur sagittis ullamcorper scelerisque,
                  blandit vitae sem. Etiam semper enim sapien, nec consequat
                  lectus pellentesque sit amet. Curabitur viverra ac tortor a
                  porttitor. Donec tristique, dui at molestie facilisis, justo
                  dolor on dapibus urna, sit amet viverra elit neque a lectus.
                  luctus maximus fore lectus. Sed egestas facilisis convallis.
                  Proin dapibus magna vitae dolor accumsan, a egestas velitEtiam
                  semper enim sapien. */}
                </p>
              </div>
            </div>
          
          </div>
          <div className="single-service-work-process">
            <h3>How We Work in our services</h3>
            <div className="single-step">
              <div className="row">
                <div className="col-md-8 col-xl-8 or2">
                  <div className="step">
                    <div className="step-count">
                     
                    </div>
                    <h4>Wireframe &amp; Design</h4>
                    <p>
                      {/* In euismod lacinia rhoncus. Morbi ornare, lectus quis
                      mattis finibus, metus sapien venenatis orci, eget lacinia
                      magna justo vehiculametus. Morbi sit amet erat faucibus,
                      sagittis libero sed, condimentum tortor. Aenean ac nunc
                      dolor. Quisque vestibulum mollis nisi, vel dictum nisi.
                      Vestibulum tempor tristique neque non pretium. Etiam leo
                      risus, consectetur sagittis ullamcorper scelerisque,
                      blandit vitae sem. */}
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-xl-4 or1">
                  <div className="step-img">
                    <img
                      src={process.env.PUBLIC_URL + "/images/nvr/br3.png"}
                      alt="images"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="single-step">
              <div className="row">
                <div className="col-md-4 col-xl-4">
                  <div className="step-img">
                    <img
                      src={process.env.PUBLIC_URL + "/images/nvr/develop.png"}
                      alt="images"
                    />
                  </div>
                </div>
                <div className="col-md-8 col-xl-8">
                  <div className="step">
                    <div className="step-count">
                    
                    </div>
                    <h4>Developing</h4>
                    <p>
                      {/* In euismod lacinia rhoncus. Morbi ornare, lectus quis
                      mattis finibus, metus sapien venenatis orci, eget lacinia
                      magna justo vehiculametus. Morbi sit amet erat faucibus,
                      sagittis libero sed, condimentum tortor. Aenean ac nunc
                      dolor. Quisque vestibulum mollis nisi, vel dictum nisi.
                      Vestibulum tempor tristique neque non pretium. Etiam leo
                      risus, consectetur sagittis ullamcorper scelerisque,
                      blandit vitae sem. */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="single-step">
              <div className="row">
                <div className="col-md-8 col-xl-8 or2">
                  <div className="step">
                    <div className="step-count">
                     
                    </div>
                    <h4>Checkup &amp; Launch</h4>
                    <p>
                      {/* In euismod lacinia rhoncus. Morbi ornare, lectus quis
                      mattis finibus, metus sapien venenatis orci, eget lacinia
                      magna justo vehiculametus. Morbi sit amet erat faucibus,
                      sagittis libero sed, condimentum tortor. Aenean ac nunc
                      dolor. Quisque vestibulum mollis nisi, vel dictum nisi.
                      Vestibulum tempor tristique neque non pretium. Etiam leo
                      risus, consectetur sagittis ullamcorper scelerisque,
                      blandit vitae sem. */}
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-xl-4 or1">
                  <div className="step-img">
                    <img
                      src={process.env.PUBLIC_URL + "/images/nvr/lv2.png"}
                      alt="images"
                    />
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ServiceDetailsWrapper;
