import React from 'react'
import LetsTalkArea from '../../common/LetsTalkArea'

const Strgt = () => {
  return (
    <section className=''>
   <main class=" p-3 hero-sectiond">
  <section class="container containerd">
    <div class="hero-contentd">
     
      <div class="hero-textd mb-lg-5 ">
        <h2 class="hero-countryd">Hire </h2>
        <h1 class="hero-welcome-textd mb-lg-5">The Best Set Of Developers For Your Work</h1>
       
      </div>
    </div>
  </section>
  
</main>
<section className="service-details sec-mar">
      
      <div className="container">
        
        <div className="row">
          <div className="col-md-6 col-lg-12 col-xl-12 or2">
            

       
            <div className="signle-service-details">
             
             
              <p>
              We provide resources to clients on a rental basis. Today the concept of virtual employees has grown tremendously. The term virtual employees represents project team members in different geographic locations. This concept has many pros and cons such as it gives the project manager a free hand to select and install the team at a significantly competitive price.

You can synchronize and streamline the technical front of your business operations by hiring passionate and professional web developers and mobile app developers that guarantee your success. We offer the most comprehensive services delivered by our developers.
              </p>
            </div>
          </div>
        
        </div>
        <div className="single-service-work-process">
          <p className='text-black text-success'>Our team of expert developers works on the latest technologies to keep up you update and upgrade.</p>
          <div className="single-step">
            <div className="row">
              <div className="col-md-8 col-xl-8 or2">
                <div className="step">
                  <div className="step-count">
                    {/* <span>01</span> */}
                  </div>
                  <h5>Novarsistech offers advanced application development services</h5>
                  <p>
                  
                  Hire an Android developer from NovarsisTech to build scalable Android apps. Novarsistech is recognized as a leading Android app development company by well-established companies that ensures reliability.
<br/>
Android app development solutions from Novarsis help all business enterprises and organizations to make their presence felt in their relevant industries.

                  </p>
                </div>
              </div>
              <div className="col-md-4 col-xl-4 or1">
                <div className="step-img m-auto  w-50">
                  <img className='border rounded-3'
                    src={process.env.PUBLIC_URL + "/images/app.png"}
                    alt="images"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="single-step">
            <div className="row">
              <div className="col-md-4 col-xl-4">
                <div className="step-img m-auto">
                  <img className='border rounded-3 ' 
                    src={process.env.PUBLIC_URL + "/images/ip.jpg"}
                    alt="images"
                  />
                </div>
              </div>
              <div className="col-md-8 col-xl-8">
                <div className="step">
                  <div className="step-count">
                   
                  </div>
                  <h4>
iOS App Development Services</h4>
                  <p>
                  Hire an iPhone app developer to experience the luxury.<br/> Being the top iPhone app development company worldwide, we provide the best solutions at budget-friendly rates.
<br/>
The iPhone revolutionized mobile computing and Internet connectivity. Today, it has become an excellent tool for high profile people as well as businesses to connect with the intended audience and complete a number of activities.
                  </p>
                  <ul>
                    <li className='fa mt-3 fa-dailymotion'>Relationship And Loyalty Building</li> <br/>

                    <li className='fa mt-3 fa-dailymotion'>Increase Visibility And Accessibility</li><br/>
                    <li className='fa mt-3  fa-dailymotion'>Increase Extra Exposure Across Mobile Devices</li><br/>
                    <li className='fa mt-3 fa-dailymotion'>Connect With On-The-Go Consumers</li>
                    <li className='fa mt-3 fa-dailymotion'>Enhance New Marketing And Relationship Strategies</li>
                    <li className='fa mt-3 fa-dailymotion'>Reinforce Brand To Generate And Repeat Business</li>
                  </ul> 
                </div>
              </div>
            </div>
          </div>
          <div className="single-step">
            <div className="row">
              <div className="col-md-8 col-xl-8 or2">
                <div className="step">
                  <div className="step-count">
                   
                  </div>
                  <h4>Native App Development Services</h4>
                  <p>
                 <strong> React Native</strong> is a  JavaScript framework and code library developed by Facebook and Instagram. <br/> It allows you to build native applications in JavaScript while still providing a true native user interface that enables code reuse across web and mobile.

                 <br/>

                 <p> <strong> Flutter</strong> is an open-source UI software development kit created by Google. It is used to develop cross-platform applications for Android, iOS, Linux, macOS, Windows, Google Fuchsia, and the web from a single codebase.
                 <br/>
               <strong>  You can hire a flexible mobile app team depending on the stage of your product and production plans.</strong>
                 </p>
            
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-xl-4 or1">
                <div className="step-img">
                  <img
                    src={process.env.PUBLIC_URL + "/images/native.jpg"}
                    alt="images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </div>
      
    </section>
    <section>
  <div id='budget' class="container-fluid m-auto">
    <div class="container">
      <div class="row">
        <div class="col-sm-4 mx-auto">
          <div class="card card_red mb-10 text-center">
            <div class="title">
              <i class="fa  fa-calendar" aria-hidden="true"></i>
              <h2>Monthly Hire</h2>
            </div>
            <div class="price">
             
            </div>
            <div class="option">
              <ul>
                <li><i class="fa fa-check" aria-hidden="true"></i>monthly hire basis</li>
                <li><i class="fa fa-check" aria-hidden="true"></i>The commitment from Novarsistech for <br/> a resource would vary <br/> range from 160-180 hours<br/> as per the client's requirement.</li>
                
                </ul>
            </div>
          
          </div>
        </div>
        <div class="col-sm-4 mx-auto">
          <div class="card card_violet text-center">
            <div class="title">
              <i class="fa fa-clock-o" aria-hidden="true"></i>
              <h2>Bucket Hour Model</h2>
            </div>
            <div class="price">
             
            </div>
            <div class="option">
              <ul>
                <li><i class="fa fa-check" aria-hidden="true"></i>suitable for clients with flexible needs</li>
                <li><i class="fa fa-check" aria-hidden="true"></i>we work on the Agile method, Prototyping,  waterfall method, Spiral method of development.</li>
                <li><i class="fa fa-check" aria-hidden="true"></i>We also offer resources on an hourly basis.</li>
                {/* <li><i class="fa fa-times" aria-hidden="true"></i>Live Support</li> */}
                </ul>
            </div>
          
          </div>
        </div>
        <div class="col-sm-4 mx-auto">
          <div class="card card_three text-center">
            <div class="title">
              <i class="h-25" aria-hidden="true"> 
              <img  class=" " height='90' alt='' src="https://novarsistech.com/wp-content/uploads/2022/05/fixed-cost.svg"/></i>
              <h2>Fixed Cost Model</h2>
            </div>
            <div class="price">
            
            </div>
            <div class="option">
              <ul>
                <li><i class="fa fa-check" aria-hidden="true"></i>we develop a set of project development models.</li>
                <li><i class="fa fa-check" aria-hidden="true"></i>understanding the need or client's requirement</li>
                <li><i class="fa fa-check" aria-hidden="true"></i>the time required to develop the software based on which a fixed price is decided</li>
               
                </ul>
            </div>
          
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    <LetsTalkArea/>
    </section>
  )
}

export default Strgt